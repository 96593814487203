import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  let year = new Date().getFullYear()

  return (
    <footer className="text-white body-font bg-footer">
      <div className="container max-w-6xl px-5 py-8 mx-auto flex items-center sm:flex-row flex-col md:px-10">
        <div className="flex flex-row title-font font-medium items-center md:justify-start justify-center text-white ">
          <StaticImage
            className="flex title-font font-medium items-center"
            src="../images/logo-white.png"
            width={30}
            height={30}
            alt="logo"
          />
          <Link to="/">
            <span className="ml-1 text-xl cursor-pointer">Alyazidi Trading Co</span>
          </Link>
        </div>

        <p className="text-sm text-white sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © {year} Alyazidi
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a
            href="https://vps63439.inmotionhosting.com:2096/"
            className="text-sm text-white flex flex-row items-center text-white"
          >
            <FontAwesomeIcon icon={faEnvelope} size="1x" />
            <span className="ml-2">Email Login</span>
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer
