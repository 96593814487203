import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faCog,
  faEnvelope,
  faBuilding,
  faBoxes,
  faTimes,
  faHome,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const SideNav = ({ inView }) => {
  const [show, setShow] = useState(false)

  const [activeNav, setActiveNav] = useState("")

  const toggleMenu = () => {
    setShow(!show)
  }

  const toggleActive = route => {
    setActiveNav(route)
    toggleMenu()
  }

  let translateX = show ? "translate-x-0" : " translate-x-full"

  return (
    <React.Fragment>
      <button
        className="md:hidden px-1"
        onClick={toggleMenu}
        aria-label="nav-button"
      >
        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          className={`${inView ? "text-gray-800" : "text-white"}`}
        />
      </button>
      <div
        className={`md:hidden flex flex-col items-center justify-center absolute top-0 right-0 w-full h-screen bg-white shadow-md overflow-hidden transition-transform duration-500 ease-in-out z-50 transform ${translateX}`}
      >
        <button className="absolute top-0 right-0 p-2 m-2" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        <Link to="/">
          <button
            onClick={() => toggleActive("/")}
            className={`text-2xl font-semibold text-gray-600 hover:text-blue-800 p-3 mt-3 flex flex-row items-center  ${
              activeNav === "/" ? "text-blue-800" : "text-gray-600"
            }`}
          >
            Home
            <FontAwesomeIcon icon={faHome} size="1x" className="ml-3" />
          </button>
        </Link>
        <Link to="/#services">
          <button
            onClick={() => toggleActive("/#services")}
            className={`text-2xl font-semibold text-gray-600 hover:text-blue-800 p-3 mt-3 flex flex-row items-center  ${
              activeNav === "/#services" ? "text-blue-800" : "text-gray-600"
            }`}
          >
            Services
            <FontAwesomeIcon icon={faCog} size="1x" className="ml-3" />
          </button>
        </Link>
        <Link to="/#products">
          <button
            onClick={() => toggleActive("/#products")}
            className={`text-2xl font-semibold text-gray-600 hover:text-blue-800 p-3 mt-3 flex flex-row items-center  ${
              activeNav === "/#products" ? "text-blue-800" : "text-gray-600"
            }`}
          >
            Products
            <FontAwesomeIcon icon={faBoxes} size="1x" className="ml-3" />
          </button>
        </Link>
        <Link to="/#about">
          <button
            onClick={() => toggleActive("/#about")}
            className={`text-2xl font-semibold text-gray-600 hover:text-blue-800 p-3 mt-3 flex flex-row items-center  ${
              activeNav === "/#about" ? "text-blue-800" : "text-gray-600"
            }`}
          >
            About
            <FontAwesomeIcon icon={faBuilding} size="1x" className="ml-3" />
          </button>
        </Link>
        <Link to="/#contact">
          <button
            onClick={() => toggleActive("/#contact")}
            className={`text-2xl font-semibold text-gray-600 hover:text-blue-800 p-3 mt-3 flex flex-row items-center  ${
              activeNav === "/#contact" ? "text-blue-800" : "text-gray-600"
            }`}
          >
            Contact
            <FontAwesomeIcon icon={faEnvelope} size="1x" className="ml-3" />
          </button>
        </Link>
      </div>
    </React.Fragment>
  )
}

export default SideNav
