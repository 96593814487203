import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import SideNav from "./sidenav"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle, inView }) => {
  const [activeNav, setActiveNav] = useState("")

  const toggleActive = route => {
    setActiveNav(route)
  }

  return (
    <header
      className={`body-font fixed w-full z-50 px-4 transition transtion-bg duration-200 ease-in ${
        inView ? "bg-white shadow" : "bg-transparent"
      }`}
    >
      <div className="container max-w-6xl mx-auto flex justify-between items-center px-5 py-4">
        {inView ? (
          <div className="flex flex-row items-center">
            <StaticImage
              className="flex title-font font-medium items-center text-gray-900"
              src="../images/logo.png"
              width={30}
              height={30}
              alt="logo"
            />
            <h1 className="text-blue-800 ml-2 font-semibold">Alyazidi Trading Co</h1>
          </div>
        ) : (
          <div className="flex flex-row items-center">
            <StaticImage
              className="flex title-font font-medium items-center text-gray-900"
              src="../images/logo-white.png"
              width={30}
              height={30}
              alt="logo"
            />
            <h1 className="text-white ml-2 font-semibold">Alyazidi Trading Co</h1>
          </div>
        )}

        <SideNav inView={inView} />
        <nav
          className={`hidden md:ml-auto md:flex flex-wrap items-center text-base font-medium justify-center ${
            inView ? "text-gray-900" : "text-white"
          }`}
        >
          <Link
            to="/"
            onClick={() => toggleActive("/")}
            className={`mr-5 transition transition-hover duration-300  ${
              activeNav === "/" && "text-indigo-500"
            } ${inView ? "hover:text-blue-800" : "hover:text-blue-400"}`}
          >
            Home
          </Link>
          <Link
            to="/#services"
            onClick={() => toggleActive("/#services")}
            className={`mr-5 transition transition-hover duration-300  ${
              activeNav === "/#services" && "text-indigo-500"
            } ${inView ? "hover:text-blue-800" : "hover:text-blue-400"}`}
          >
            Services
          </Link>
          <Link
            to="/#products"
            onClick={() => toggleActive("/#products")}
            className={`mr-5 transition transition-hover duration-300 ${
              activeNav === "/#products" && "text-indigo-500"
            } ${inView ? "hover:text-blue-800" : "hover:text-blue-400"}`}
          >
            Products
          </Link>
          <Link
            to="/#about"
            onClick={() => toggleActive("/#about")}
            className={`mr-5 transition transition-hover duration-300  ${
              activeNav === "/#about" && "text-indigo-500"
            } ${inView ? "hover:text-blue-800" : "hover:text-blue-400"}`}
          >
            About
          </Link>
          <Link
            to="/#contact"
            onClick={() => toggleActive("/#contact")}
            className={`mr-5 transition transition-hover duration-300 ${
              activeNav === "/#contact" && "text-indigo-500"
            } ${inView ? "hover:text-blue-800" : "hover:text-blue-400"}`}
          >
            Contact
          </Link>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
