/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "aos/dist/aos.css"
import AOS from "aos"
import "../sass/global.scss"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ inView, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    })
    AOS.refresh()
  }, [])

  return (
    <div className="flex flex-col min-h-screen">
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        inView={inView}
      />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
